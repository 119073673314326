import router from '@/router';
import * as queries from "@/graphql/queries";
import * as allMutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";
import {
    listSensorReadings,
    sortedListSensors,
    distributorSensors,
    ownerSensors,
    listUserSensors,
    listSensorReports,
    listAllSensors,
    listStoreSensors,
    listNotificationsBySensor,
    listAlarmsBySensor,
    dataReadings,
    testingSensors,
    listCustomerSensors,
    listSuperSensors,
    updateSensor,
    getDevice,
    listMDXSensorReadings,
    createSensor,
    listTestingSensors,
    updateSensorSimple,
    getStoreAdminSensors,
    getLocationAdminSensors,
    listSingleSensorReadings,
    updateAllSensors,
    updateAllCustomerSensors
} from "@/store/custom"
import {
    API
} from "aws-amplify"
import {getReport} from "@/graphql/queries";
import { getSortedSensorReports } from "@/graphql/queries";
import { onCreateReading } from "@/graphql/subscriptions";
import { F, product, update } from 'ramda';
// import { moveStock} from '../../graphql/mutations';

const state = {
    sensor: [],
    sensors: [],
    allSubscribe: "",
    singleSubscribe: "",
    sensorsSubscribe: "",
    logSubscribe: "",
    sensorSubscribe: "",
    newSensorSubscribe: "",
    sensorDeleteSubscribe: "",
    deviceType: "",
    tags: [],
    locations: [],
    sensorDetails: {},
    logs: [],
    alarms: [],
    sensorEvents: [],
    sensorProducts: [],
    stock: [],
    devices: [],
    btDevice: "",
}

const getters = {
    tdxSensors(state) {
        return state.sensors.filter(item => {
            if (item.device && item.device.model === "tdx") return true
            else return false
        })
    },
    kdxSensors(state) {
        return state.sensors.filter(item => {
            if (item.device && item.device.model === "C314") return true
            else return false
        })
    },
    mdxSensors(state) {
        return state.sensors.filter(item => {
            if (item.device && item.device.model === "mdx") return true
            else return false
        })
    },
    activeAlarms(state) {
        if (Object.keys(state.sensorDetails).length === 0) return []
        else return state.sensorDetails.alarms.items.filter(item => item.active)
    }
}

const actions = {
    sortAndSetAlarms({commit}, alarms) {
        let sorted = alarms.map(item => {
            if (item.type === "connectivity") item.order = 2
            else if (item.type === "delay") item.order = 1
            else if (item.type === "high") item.order = 3
            else if (item.type === "low") item.order = 4
            return item
        })
        commit("setAlarms", sorted)
    },
    async getAdminStoreSensors({
        state,
        commit,
        dispatch
    }, userId) {
        console.log('getAdminStoreSensors', state.sensors)
        const {
            data: {
                getUser: {
                    store: {
                        locations: {
                            items
                        }
                    }
                }
            }
        } = await API.graphql({
            query: getStoreAdminSensors,
            variables: {
                id: userId
            }
        })
        commit("setSensors", [].concat(...items.map(item => item.sensors.items)))
    },
    async getAdminLocationSensors({
        commit,
        dispatch
    }, userId) {
        const {
            data: {
                getUser: {
                    location: {
                        sensors: {
                            items
                        }
                    }
                }
            }
        } = await API.graphql({
            query: getLocationAdminSensors,
            variables: {
                id: userId
            }
        })
        commit("setSensors", items)
    },
    async getStoreSensors({
        commit,
        dispatch
    }, store) {
        const {
            data: {
                listStores: {
                    items
                }
            }
        } = await API.graphql({
            query: listStoreSensors,
            variables: {
                filter: {
                    name: {
                        eq: store
                    }
                }
            }
        })
        commit("setSensors", await dispatch("formatStoreSensors", items))
        dispatch("readingSubscription")
    },
    async getSensorNotifications({
        commit,
        dispatch
    }, uuid) {
        const {
            data: {
                sensorByUUID: {
                    items
                }
            }
        } = await API.graphql({
            query: listNotificationsBySensor,
            variables: {
                uuid
            }
        })
        return items
    },
    async getSensorAlarms({
        commit,
        dispatch
    }, uuid) {
        const {
            data: {
                sensorByUUID: {
                    items
                }
            }
        } = await API.graphql({
            query: listAlarmsBySensor,
            variables: {
                uuid
            }
        })
        let alarm
        if (router.currentRoute.params.duelId) {
            alarm = items.filter(item => item.duelId === router.currentRoute.params.duelId)[0]
        }
        else alarm = items[0]
        dispatch("sortAndSetAlarms", alarm.alarms.items)
        return alarm.id
    },
    async getSensorEvents({
        commit,
        dispatch
    }, uuid) {
        const {
            data: {
                sensorByUUID: {
                    items
                }
            }
        } = await API.graphql({
            query: listAlarmsBySensor,
            variables: {
                uuid
            }
        })
        let alarm
        if (router.currentRoute.params.duelId) {
            alarm = items.filter(item => item.duelId === router.currentRoute.params.duelId)[0]
        }
        else alarm = items[0]
        dispatch("sortAndSetAlarms", alarm.alarms.items)
        return alarm.id
    },
    async addNotification({
        commit,
        dispatch
    }, notification) {
        const {
            data: {
                createNotification
            }
        } = await API.graphql({
            query: allMutations.createNotification,
            variables: {
                input: {
                    type: notification.type,
                    info: notification.info,
                    nickname: notification.nickname,
                    notificationSensorId: notification.sensor
                }
            }
        })
        if (createNotification.id) {
            return createNotification
        } else {
            return false
        }
    },
    async addSession({
        commit,
        dispatch
    }, {sensorId, productId, high, low, min, max}) {
        const {
            data: {
                createSession
            }
        } = await API.graphql({
            query: allMutations.createSession,
            variables: {
                input: {
                    sessionSensorId: sensorId,
                    sessionProductId: productId,
                    high,
                    low,
                    min,
                    max
                }
            }
        })
        if (createSession.id) {
            // commit("addProductSession", createSession)
            return createSession
        }
        else return false
    },
    async removeSession({
        commit,
        dispatch
    }, id) {
        const {
            data: {
                deleteSession
            }
        } = await API.graphql({
            query: allMutations.deleteSession,
            variables: {
                input: {
                    id
                }
            }
        })
        if (deleteSession.id) return true
        else return false
    },
    async changeProduct({
        commit,
        dispatch
    }, product) {
        const {
            data: {
                updateProduct
            }
        } = await API.graphql({
            query: allMutations.updateProduct,
            variables: {
                input: {
                    id: product.id,
                    [product.prop]: product.val
                }
            }
        })
        if (updateProduct.id) return true
        else return false
    },
    async addNewReading({
        commit,
        dispatch
    }, {sensorId, sessionId, temp}) {
        try {
            const {
                data: {
                    createReading
                }
            } = await API.graphql({
                query: allMutations.createReading,
                variables: {
                    input: {
                        createdAt: new Date().toISOString(),
                        readingSensorId: sensorId,
                        readingSessionId: sessionId,
                        min: temp,
                    }
                }
            })
            if (createReading.id) {
                return createReading
            } 
            //else {
            //     return false
            // }
        } catch (error) {
            console.log(error)
        }
    },
    // async getSessions({
        
    // }, sensorId) {
    //     const {
    //         data: {
    //             listSessions
    //         }
    //     } = await API.graphql({
    //         query: queries.listSessions,
    //         variables: {
                
    //         }
    // },
    async addAlarm({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                createAlarm
            }
        } = await API.graphql({
            query: allMutations.createAlarm,
            variables: {
                input: {
                    input: alarm.input,
                    value: alarm.value,
                    predicate: alarm.predicate,
                    duration: alarm.duration,
                    alarmSensorId: alarm.alarmSensorId ? alarm.alarmSensorId : alarm.sensor,
                    default: alarm.default ? alarm.default : null,
                    type: alarm.type ? alarm.type : 'value',
                    pause: alarm.pause,
                    timezone: alarm.timezone,
                    start: alarm.start,
                    stop: alarm.stop
                }
            }
        })
        if (createAlarm.id) return createAlarm
        else return false
    },
    async removeNotification({
        commit,
        dispatch
    }, id) {
        const {
            data: {
                deleteNotification
            }
        } = await API.graphql({
            query: allMutations.deleteNotification,
            variables: {
                input: {
                    id
                }
            }
        })
        if (deleteNotification.id) return true
        else return false
    },
    async removeAlarm({
        commit,
        dispatch
    }, id) {
        const {
            data: {
                deleteAlarm
            }
        } = await API.graphql({
            query: allMutations.deleteAlarm,
            variables: {
                input: {
                    id
                }
            }
        })
        if (deleteAlarm.id) return true
        else return false
    },
    async updateAlarmPropertyTemps({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                updateAlarm
            }
        } = await API.graphql({
            query: allMutations.updateAlarm,
            variables: {
                input: {
                    id: alarm.id,
                    value: alarm.value,
                    valueC: alarm.valueC
                }
            }
        })
        if (updateAlarm.id && alarm.update) {
            await dispatch("updateSensorPropertyTemps", {
                id: alarm.sensorId,
                low: alarm.type === "low" ? alarm.value : undefined,
                lowC: alarm.type === "low" ? alarm.valueC : undefined,
                high: alarm.type === "high" ? alarm.value : undefined,
                highC: alarm.type === "high" ? alarm.valueC : undefined
            })
            return updateAlarm
        }
        else if (updateAlarm.id) return updateAlarm
        else return false
    },
    async updateAlarmProperty({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                updateAlarm
            }
        } = await API.graphql({
            query: allMutations.updateAlarm,
            variables: {
                input: {
                    id: alarm.id,
                    // type: alarm.prop,
                    value: alarm.prop !== "duration" ? alarm.newValue : undefined,
                    duration: alarm.prop === "duration" ? alarm.newValue : undefined
                }
            }
        })
        if (updateAlarm.id) return updateAlarm
        else return false
    },
    async updateAlarmDuration({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                updateAlarm
            }
        } = await API.graphql({
            query: allMutations.updateAlarm,
            variables: {
                input: {
                    id: alarm.id,
                    duration: alarm.duration
                }
            }
        })
        if (updateAlarm.id) return updateAlarm
        else return false
    },
    async updateAlarmTemp({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                updateAlarm
            }
        } = await API.graphql({
            query: allMutations.updateAlarm,
            variables: {
                input: {
                    id: alarm.id,
                    value: alarm.temp
                }
            }
        })
        if (updateAlarm.id) return updateAlarm
        else return false
    },
    async updateAlarm({
        dispatch
    }, alarm) {
        try {
            let [alarmTemp, sensorTemp] = await Promise.all([dispatch('updateAlarmTemp', alarm), dispatch('updateSensorTemp', {
                id: alarm.sensorId,
                value: alarm.temp,
                prop: alarm.prop,
            })])
            if (alarmTemp && sensorTemp) return sensorTemp
            else return false
        } catch (error) {
            console.log("error updating alarm", error)
            return false
        }
    },
    async updateSensorTemp({
        commit,
        dispatch
    }, sensor) {
        const {
            data: {
                updateSensor
            }
        } = await API.graphql({
            query: allMutations.updateSensor,
            variables: {
                input: {
                    id: sensor.id,
                    [sensor.prop]: sensor.value
                }
            }
        })
        if (updateSensor.id) return updateSensor
        else return false
    },
    async updateAlarmHours({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                updateAlarm
            }
        } = await API.graphql({
            query: allMutations.updateAlarm,
            variables: {
                input: {
                    id: alarm.id,
                    start: alarm.start,
                    stop: alarm.stop,
                    timezone: alarm.timezone
                }
            }
        })
        if (updateAlarm.id) return updateAlarm
        else return false
    },
    async pauseAlarm({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                updateAlarm
            }
        } = await API.graphql({
            query: allMutations.updateAlarm,
            variables: {
                input: {
                    id: alarm.id,
                    pause: alarm.pause
                }
            }
        })
        if (updateAlarm.id) return updateAlarm
        else return false
    },
    async getUserSensors({
        commit,
        dispatch
    }, userId) {
        const {
            data: {
                getUser
            }
        } = await API.graphql({
            query: listUserSensors,
            variables: {
                id: userId
            }
        })
        if (!getUser.store) commit("setSensors", await dispatch("formatUserSensors", [getUser.location]))
        else commit("setSensors", await dispatch("formatUserSensors", getUser.store.locations.items))
        dispatch("readingSubscription")
    },
    async getDemoSensors({
        commit,
        dispatch
    }, deviceId) {
        const {
            data
        } = await API.graphql({
            query: getDevice,
            variables: {
                id: deviceId
            }
        })
        let all = data.getDevice.sensors.items.filter(item => item.name && item.test !== true)
        let filtered = all.sort((a, b) => a.name - b.name)
        commit("setSensors", filtered)
        // dispatch("readingSubscription")
    },
    async getCustomerSensors({
        commit,
        dispatch
    }, userId) {
        const {
            data: {
                getCustomer: {
                    sensors
                }
            }
        } = await API.graphql({
            query: listCustomerSensors,
            variables: {
                id: userId
            }
        })
        if (!sensors.items.length) {
            return false
        } else {
            commit("setSensors", await dispatch("formatCustomerSensors", sensors.items))
            return true
        }
        // if (!getUser.store) commit("setSensors", await dispatch("formatUserSensors", [getUser.location]))
        // else commit("setSensors", await dispatch("formatUserSensors", getUser.store.locations.items))
        // dispatch("readingSubscription")
    },
    async allTags({
        commit
    }) {
        const {
            data: {
                listTags: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.listTags,
            variables: {}
        })
        commit("setTags", items)
    },
    async addTag({
        commit
    }, tagName) {
        const {
            data: {
                createTag
            }
        } = await API.graphql({
            query: allMutations.createTag,
            variables: {
                input: {
                    name: tagName
                }
            }
        })
        commit("addNewTag", createTag)
        return createTag
    },
    async createTTN({
    }, {devEui, joinEui, appKey}) {
        const {
            data
        } = await API.graphql({
            query: allMutations.createTTNSensor,
            variables: {
                devEui,
                joinEui,
                appKey
            }
        })
        return data.createTTNSensor
    },
    async acknowledge({
    }, {alarmId, eventId, responseBy, sensorId, secondAlarm}) {
        const {
            data
        } = await API.graphql({
            query: allMutations.acknowledge,
            variables: {
                alarmId,
                responseBy,
                eventId,
                sensorId,
                secondAlarm
            }
        })
        return data.acknowledge
    },
    async updateSensorPropertySimple({
        commit,
        dispatch
    }, sensor) {
        const {
            data: {
                updateSensor
            }
        } = await API.graphql({
            query: updateSensorSimple,
            variables: {
                input: {
                    id: sensor.id,
                    [sensor.prop]: sensor.value
                }
            }
        })
        commit("updateSensor", updateSensor)
    },
    async updateSensorPropertyTemps({
        commit,
        dispatch
    }, sensor) {
        const {
            data: {
                updateSensor
            }
        } = await API.graphql({
            query: updateSensorSimple,
            variables: {
                input: {
                    id: sensor.id,
                    low: sensor.low,
                    lowC: sensor.lowC,
                    high: sensor.high,
                    highC: sensor.highC
                }
            }
        })
        if (sensor.updateAlarms) {
            let high = updateSensor.alarms.items.find(item => item.type === "high")
            let low = updateSensor.alarms.items.find(item => item.type === "low")
            if (sensor.low || sensor.lowC) {
                await dispatch("updateAlarmPropertyTemps", {
                    id: low.id,
                    value: sensor.low,
                    valueC: sensor.lowC,
                    update: false
                })
            }
            else if (sensor.high || sensor.highC) {
                await dispatch("updateAlarmPropertyTemps", {
                    id: high.id,
                    value: sensor.high,
                    valueC: sensor.highC,
                    update: false
                })
            }
        }
        commit("updateSensor", updateSensor)
        return true
    },
    async updateSensorProperty({
        commit,
        dispatch
    }, sensor) {
        const {
            data: {
                updateSensor
            }
        } = await API.graphql({
            query: allMutations.updateSensor,
            variables: {
                input: {
                    id: sensor.id,
                    [sensor.prop]: sensor.value
                }
            }
        })
        let allAlarms = updateSensor.alarms.items.filter(item => item.type === sensor.prop)
        if (sensor.prop === "name") return true
        else if (!sensor.durationChange && allAlarms.length) {
            let [alarm] = updateSensor.alarms.items.filter(item => item.type === sensorSearch)
            await dispatch("updateAlarmProperty", {
                id: alarm.id,
                newValue: sensor.value
            })
            return true
        } else if (sensor.durationChange) {
            // console.log('duration change');
            let alarms = updateSensor.alarms.items.filter(item => item.value !== null && item.default)
            // console.log(alarms)
            let newAlarms = alarms.map(item => {
                item.prop = sensor.prop
                item.newValue = sensor.value
                return item
            })
            // console.log(newAlarms)
            let finalAlarms = await Promise.all(
                newAlarms.map(async item => {
                    return await dispatch("updateAlarmProperty", item)
                })
            )
            // console.log(finalAlarms)
            return true
        } else if (updateSensor.id) {
            // console.log('adding new alarm')
            let newAlarm;
            let index = updateSensor.alarms.items.findIndex(item => item.type === sensor.prop)
            if (index === -1) {
                // console.log('calling addAlarm');
                newAlarm = await dispatch("addAlarm", {
                    input: "temp",
                    default: true,
                    type: sensor.prop,
                    value: sensor.value,
                    predicate: sensor.prop === 'low' ? 'lt' : 'gt',
                    duration: sensor.duration,
                    alarmSensorId: sensor.id
                })
                if (newAlarm.id) {
                    return true
                } else return false
            } else {
                // console.log('updating alarm property')
                let alarm = updateSensor.alarms.items[index]
                alarm.prop = sensor.prop
                alarm.newValue = sensor.value
                newAlarm = await dispatch("updateAlarmProperty", alarm)
                if (newAlarm.id) {
                    return true
                } else return false
            }
        } else {
            // console.log('no alarm updates');
            return false
        }
    },
    async updateDev({
        commit
    }, sensor) {
        commit("updateSensorMode", sensor)
        const {
            data: {
                updateSensor
            }
        } = await API.graphql({
            query: allMutations.updateSensor,
            variables: {
                input: {
                    id: sensor.id,
                    development: sensor.development
                }
            }
        })
        if (updateSensor.development === sensor.development) return true
        else {
            commit("reverseSensorMode", sensor)
            return false
        }
    },
    async addSensorAlarms({
        dispatch
    }, alarms) {
        let newAlarms = await Promise.all(
            alarms.map(async item => {
                return await dispatch("addAlarm", item)
            })
        )
        return newAlarms
    },
    async addSensorNotifications({
        dispatch
    }, notifications) {
        let newNotifications = await Promise.all(
            notifications.map(async item => {
                return await dispatch("addNotification", item)
            })
        )
        return newNotifications
    },
    async addSensorTag({
        commit
    }, tagProps) {
        const {
            data: {
                createSensorTag
            }
        } = await API.graphql({
            query: allMutations.createSensorTag,
            variables: {
                input: {
                    sensorTagTagId: tagProps.tag,
                    sensorTagSensorId: tagProps.sensor
                }
            }
        })
        commit("addNewSensorTag", createSensorTag)
    },
    async userSubs({
        commit
    }) {

    },
    async addUserSub({
        commit,
        rootState
    }, subId) {
        const {
            data: {
                createUserSub
            }
        } = await API.graphql({
            query: allMutations.createUserSub,
            variables: {
                input: {
                    userSubSubId: subId,
                    userSubUserId: rootState.user.username
                }
            }
        })
        // console.log(createUserSub)
        // commit("addNewUserSub", createUserSub)
    },
    async addLocation({
        commit
    }, location) {
        const {
            data: {
                createLocation
            }
        } = await API.graphql({
            query: allMutations.createLocation,
            variables: {
                input: location
            }
        })
        return createLocation.id
    },
    async allLocations({
        commit
    }) {
        const {
            data: {
                listLocations: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.listLocations,
            variables: {}
        })
        commit("setLocations", items)
    },
    async checkLocation({}, locationId) {
        const {
            data: {
                getLocation
            }
        } = await API.graphql({
            query: queries.getLocation,
            variables: {
                id: locationId
            }
        })
        if (getLocation) return getLocation.id
        else return false
    },
    async addSensor({
        state,
        commit,
        getters,
        dispatch
    }, sensor) {
        let env = API._options.aws_content_delivery_bucket.split("-")[2]
        // check code for userId
        if (sensor.code) {
            let code = await dispatch("checkCode", sensor.code)
            if (!code) return "code not found"
            else {
                sensor.userId = code.user.id
            }
        }
        // add to TTN
        if (sensor.joinEui) {
            let ttnSensor = await dispatch("createTTN", {
                devEui: sensor.uuid.toUpperCase(),
                joinEui: sensor.joinEui.toUpperCase(),
                appKey: sensor.appKey.toUpperCase()
            })
            if (ttnSensor !== "success") {
                return "error adding to TTN"
            }
        }
        const {
            data: {
                createSensor
            }
        } = await API.graphql({
            query: allMutations.createSensor,
            variables: {
                input: {
                    uuid: sensor.uuid,
                    name: sensor.name,
                    sensorDeviceId: env === "tldev" ? "9e1e12fe-e2a1-42a7-98ea-c0fe8ff89e94" : "a9bba87b-5b0a-4548-8bf7-82dd3fc45bcf",
                    active: true,
                    development: true,
                    sensorUserId: sensor.userId ? sensor.userId : undefined,
                    duration: sensor.duration,
                    high: sensor.high,
                    low: sensor.low,
                    phone: sensor.phone ? sensor.phone : null,
                    email: sensor.email ? sensor.email : null,
                    raw: sensor.raw ? sensor.raw : null,
                    duel: sensor.duel ? sensor.duel : null,
                    duelId: sensor.duelId ? sensor.duelId : null,
                    alert: false,
                    alarm: false,
                    timezone: "America/Chicago"
                }
            }
        })
        if (createSensor) {
            // remove sensor from stock inventory
            if (sensor.removeStock) await dispatch("removeStock", sensor.uuid)
            return true
        } else return false
    },
    async removeSensor({
        commit,
        state
    }, sensorId) {
        const {
            data: {
                deleteSensor
            }
        } = await API.graphql({
            query: allMutations.deleteSensor,
            variables: {
                input: {
                    id: sensorId
                }
            }
        })
        if (deleteSensor.id) {
            // commit("removeSingleSensor", deleteSensor.id)
            return true
        } else return false

    },
    async removeStock({
        commit,
        state
    }, devEui) {
        const {
            data: {
                deleteStock
            }
        } = await API.graphql({
            query: allMutations.deleteStock,
            variables: {
                input: {
                    devEui
                }
            }
        })
        if (deleteStock.devEui) {
            return true
        } else return false

    },
    async removeSensorTag({
        commit
    }, sensorTag) {
        const {
            data: {
                deleteSensorTag
            }
        } = await API.graphql({
            query: allMutations.deleteSensorTag,
            variables: {
                input: {
                    id: sensorTag
                }
            }
        })
        commit("removeTag", deleteSensorTag)
    },
    async removeSensorReadings({
        commit
    }, sensorId) {

    },
    async checkCode({
        commit
    }, code) {
        const {
            data: {
                getCode
            }
        } = await API.graphql({
            query: queries.getCode,
            variables: {
                code
            }
        })
        return getCode
    },
    async getSensorReadings({
        commit
    }, sensorId) {
        const {
            data: {
                readingsForSensor: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.readingsForSensor,
            variables: {
                sensorId: sensorId
            }
        })

    },
    async getSensorByUuid({
        commit,
        dispatch
    }, {uuid}) {
        const {
            data: {
                sensorByUUID: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.sensorByUUID,
            variables: {
                uuid
            }
        })
        return items[0]
    },
    async getDevices({
        commit
    }) {
        const { data } = await API.graphql({
            query: queries.listDevices
        })
        commit("setDevices", data.listDevices.items)
    },
    async moveStockToDash({
        commit
    }, {devEui, name, email, sensorLocationId, profile, sensorDeviceId, sensorUserId}) {
        const {
            data: {
                moveStock
            }
        } = await API.graphql({
            query: allMutations.moveStock,
            variables: {
                devEui,
                name,
                email,
                sensorLocationId,
                profile,
                sensorDeviceId,
                sensorUserId
            }
        })
        if (moveStock) {
            commit("removeStockItem", devEui)
        }
    },
    async adminUpdateSensor({
        commit
    }, sensor) {
        const {
            data: {
                adminUpdates
            }
        } = await API.graphql({
            query: allMutations.adminUpdates,
            variables: {
                func: "updateSensor",
                val: JSON.stringify(sensor)
            }
        })
        if (adminUpdates) return true
        else return false
    },
    async adminCopyAlert({
        commit
    }, alert) {
        const {
            data: {
                adminUpdates
            }
        } = await API.graphql({
            query: allMutations.adminUpdates,
            variables: {
                func: "copyAlert",
                val: JSON.stringify(alert)
            }
        })
        if (adminUpdates) return true
        else return false
    },
    async updateEventNotes({
    }, event) {
        const {
            data: {
                adminUpdates
            }
        } = await API.graphql({
            query: allMutations.adminUpdates,
            variables: {
                func: "updateEventNotes",
                val: JSON.stringify(event)
            }
        })
        if (adminUpdates) return true
        else return false
    },
    async getSensorStock({
        commit,
        dispatch
    }, {allStock, token} = {allStock: [], token: null}) {
        const {
            data: {
                listStocks: {
                    items,
                    nextToken
                }
            }
        } = await API.graphql({
            query: queries.listStocks,
            variables: {
                nextToken: token,
                limit: 1500
            }
        })
        if (nextToken) {
            return dispatch("getSensorStock", {allStock: allStock.concat(...items), token: nextToken})
        }
        else {
            let finalStock = allStock.concat(...items).map(item => {
                if (!item.model) item.model = "LHT65"
                item.profile = "cooler"
                return item
            })
            commit("setSensorStock", finalStock)
        }
    },
    async checkSensor({
        dispatch
    }, uuid) {
        let sensor = await dispatch("getSensorByUuid", {uuid})
        // no sensor found
        if (!sensor) {
            // check stock db for sensor
            let stock = await dispatch("checkStock", uuid)
            if (stock) return "ready"
            else return "add to TTN"
        }
        // sensor already exists
        else return "sensor exists"
    },
    async checkStock({
    }, devEui) {
        const {
            data
        } = await API.graphql({
            query: queries.getStock,
            variables: {
                devEui
            }
        })
        if (data.getStock) return true
        else return false
    },
    // REMOVE UNUSED FUNCTION
    // async getSensorInfo({
    //     commit,
    //     dispatch
    // }, sensorUUID) {
    //     const {
    //         data: {
    //             listSensors: {
    //                 items
    //             }
    //         }
    //     } = await API.graphql({
    //         query: queries.listSensors,
    //         variables: {
    //             filter: {
    //                 uuid: {
    //                     eq: sensorUUID
    //                 }
    //             },
    //             limit: 2000
    //         }
    //     })
    //     // console.log("info",
    //     //     items)
    //     if (items.length) return items
    //     else return false
    // },
    async testConnectivity({
    }) {
        const {
            data
        } = await API.graphql({
            query: queries.checkConnectivity
        })
    },
    async singleSensor({
        commit,
        dispatch
    }, sensor) {
        const {
            data: {
                sensorByUUID: {
                    items
                }
            }
        } = await API.graphql({
            query: listSingleSensorReadings,
            variables: {
                uuid: sensor.uuid
            }
        })
        let res = items
        if (items.length > 1) {
            res = items.filter(item => item.duelId === sensor.duelId)
        }
        let device = "TDX"
        if (res[0].device.model === "ONE") device = "ONE"


    
        let readings = res[0].readings.items.reverse()
        // dispatch("setupLogs", {
        //     id: res[0].id,
        //     items: res[0].logs.items
        // })
        commit("setDeviceType", device)

        if (!res[0].timezone) {
            dispatch("adminUpdateSensor", {id: res[0].id, timezone: "America/Chicago"})
            res[0].timezone = "America/Chicago"
        }
        commit("setSensorDetails", res[0])
        commit("setSensorEvents", res[0].events.items)
        commit("setSensorProducts", res[0].products.items)
        if (device === "ONE") {
            return res[0]
        }
        else if (readings.length) {
            commit("setSensor", readings)
            dispatch("singleReadingSubscription", readings[0].readingSensorId)
            return res[0]
        }
        return res[0].displayValues
    },
    logSubscription({
        commit,
        state
    }, sensorId) {
        let sub = API.graphql({
            query: subscriptions.onCreateLog
        }).subscribe({
            next: logData => {
                let {
                    value: {
                        data: {
                            onCreateLog
                        }
                    }
                } = logData;
                if (!onCreateLog.sensor) return
                if (sensorId === onCreateLog.sensor.id) {
                    commit("addLog", onCreateLog);
                    // if (onCreateLog.trigger === "offline") {
                    //     commit("updateSensorDetailsOffline")
                    // }
                }
            }
        });
        commit("setLogSubscribe", sub)
    },
    async setupLogs({ dispatch, commit }, logs) {
        commit("setLogs", logs.items)
        dispatch("logSubscription", logs.id)
    },
    async singleMDXSensor({
        commit,
        dispatch
    }, sensorUUID) {
        const {
            data: {
                listSensors: {
                    items
                }
            }
        } = await API.graphql({
            query: listMDXSensorReadings,
            variables: {
                filter: {
                    uuid: {
                        eq: sensorUUID
                    }
                },
                limit: 1000
            }
        })
        let readings = items[0].readings.items.reverse()
        commit("setDeviceType", "MDX")
        commit("setSensorDetails", items[0])
        if (readings.length) {
            commit("setSensor", readings)
            dispatch("singleReadingSubscription", readings[0].readingSensorId)
        }
        return items[0].displayValues
    },
    async getSingleSensor({
        commit,
        dispatch
    }, uuid) {
        const {
            data: {
                sensorByUUID: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.sensorByUUID,
            variables: {
                uuid
            }
        })
        if (items.length) return items[0]
        else return false
    },
    async getDistributorSensors({
        commit,
        dispatch
    }, dist) {
        const {
            data: {
                listDistributors: {
                    items
                }
            }
        } = await API.graphql({
            query: listAllSensors,
            variables: {
                filter: {
                    name: {
                        eq: dist
                    }
                }
            }

        })
        commit("setSensors", await dispatch("formatSensors", items))
        dispatch("readingSubscription")
    },
    async pullReadings({
        commit,
        dispatch,
        state
    }, sensor) {
        let readings = []
        if (sensor.readings) {
            readings = sensor.readings
        }
        const {
            data: {
                checkReadings: {
                    items,
                    nextToken
                }
            }
        } = await API.graphql({
            query: dataReadings,
            variables: {
                sensorId: sensor.id,
                number: sensor.number,
                unit: sensor.unit,
                nextToken: sensor.nextToken ? sensor.nextToken : null,
                from: sensor.from ? sensor.from : null,
                to: sensor.to ? sensor.to : null
            }
        })

        if (nextToken) {
            return dispatch("pullReadings", {
                id: sensor.id,
                number: sensor.number,
                unit: sensor.unit,
                readings: [].concat(...items, ...readings),
                from: sensor.from ? sensor.from : null,
                to: sensor.to ? sensor.to : null,
                nextToken
            })
        } else {
            if (readings.length) {
                var allItems = [].concat(...items, ...readings)
                allItems.sort((a, b) => {
                    return a.createdAt > b.createdAt ? 1 : -1
                })
            }
        }
        if (state.singleSubscribe) {
            await dispatch("unsubscribeSingleSensor")
        }

        if (!items.length) return false
        else {
            commit("setSensor", allItems ? allItems : items)
            return allItems ? allItems : items
        }

    },
    async filterSensors({}, sensors) {
        let active = sensors.filter(item => item.active === true && item.readings.items.length)
        let sortedItems = [...active].sort((a, b) => Number(a.label) > Number(b.label) ? 1 : -1)
        return sortedItems.map(item => {
            return {
                id: item.id,
                readings: item.readings.items.length ? item.readings.items : [],
                uuid: item.uuid,
                label: item.label,
                distributor: item.distributor.name,
                owner: item.owner.name,
                tags: item.tags.items.length ? item.tags.items.map(item => {
                    return {
                        id: item.tag.id,
                        name: item.tag.name,
                        sensorTagId: item.id
                    }
                }) : [],
                location: item.location.name
            }
        })
    },
    async formatUserSensors({}, userLocations) {
        let allSensors = []
        userLocations.forEach((item, i) => {
            let location = item
            let sensors = item.sensors.items
            sensors.forEach(item => {
                item.location = location
                item.reading = item.readings.items[0]
            })
            allSensors.push(sensors)
        })
        return ([].concat(...allSensors)).sort((a, b) => Number(a.label) - Number(b.label))
    },
    async formatCustomerSensors({}, sensors) {
        let all = sensors.map(item => {
            // item.location = item
            item.reading = item.readings.items[0]
            return item
        })
        return all.sort((a, b) => Number(a.label) - Number(b.label))
    },
    async testingSensors({
        commit,
        dispatch
    }) {
        const {
            data: {
                listSensors: {
                    items
                }
            }
        } = await API.graphql({
            query: testingSensors,
            variables: {
                limit: 2000,
                filter: {
                    test: {
                        eq: true
                    },
                    development: {
                        eq: false
                    }
                }
            }
        })
        items.sort((a, b) => {
            return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
        })
        dispatch("readingSubscription")
        return items
        // commit("setSensors", items)
    },
    async formatStoreSensors({}, stores) {
        let allSensors = []
        stores.forEach((item, i) => {
            let store = item
            let locations = store.locations.items
            locations.forEach((item, i) => {
                let location = item
                let sensors = item.sensors.items
                sensors.forEach(item => {
                    item.store = store
                    item.location = location
                    item.reading = item.readings.items[0]
                })
                allSensors.push(sensors)
            })
        })
        return ([].concat(...allSensors)).sort((a, b) => Number(a.label) - Number(b.label))
    },
    async formatSensors({}, dist) {
        let allSensors = []
        dist.forEach((item, i) => {
            let distributor = item
            let stores = distributor.stores.items
            stores.forEach(item => {
                let store = item
                let locations = store.locations.items
                locations.forEach(item => {
                    let location = item
                    let sensors = item.sensors.items
                    sensors.forEach(item => {
                        item.distributor = distributor
                        item.store = store
                        item.location = location
                        item.reading = item.readings.items[0]
                    })
                    let filtered = sensors.filter(item => item.test !== true)
                    allSensors.push(filtered)
                })
            })
        })
        return ([].concat(...allSensors)).sort((a, b) => Number(a.label) - Number(b.label))
    },
    async allSensors({
        commit,
        dispatch
    }, {sensors, token} = {sensors: [], token: null}) {
        const {
            data: {
                listSensors: {
                    items,
                    nextToken
                }
            }
        } = await API.graphql({
            query: listSuperSensors,
            variables: {
                nextToken: token,
                limit: 500
            }
        })
        if (nextToken) {
            let all = sensors.concat(...items).filter(item => item.name)
            commit("setSensors", all)
            return dispatch("allSensors", {sensors: [], token: nextToken})
            // return dispatch("allSensors", {sensors: sensors.concat(...items), token: nextToken})
        }
        else {
            let all = sensors.concat(...items).filter(item => item.name)
            commit("setSensors", all)
        }
        // if (nextToken) {
        //     commit("setSensors", items.filter(item => item.name))
        //     return dispatch("allSensors", {token: nextToken})
        // }
        // else {
        //     // let all = sensors.concat(...items).filter(item => item.name)
        //     commit("setSensors", items.filter(item => item.name))
        // }
    },
    async testingSensors({
        commit,
        dispatch
    }, model) {
        model = "tdx"
        const {
            data: {
                listSensors: {
                    items,
                    nextToken
                }
            }
        } = await API.graphql({
            // query: listAllSensors,
            query: listTestingSensors,
            variables: {
                limit: 500,
                filter: {
                    validated: {
                        eq:  false
                    }
                }
            }
        })
        let all = items.filter(item => item.name)
        // let filtered = all.sort((a, b) => a.name - b.name)
        // commit("setSensors", await dispatch("formatSensors", items))
        commit("setSensors", all)
    },
    sensorSubscription({
        commit,
        state,
        dispatch
    }, id) {
        if (state.sensorSubscribe) return
        let sub = API.graphql({
            query: updateSensor,
            variables: {
                id
            }
        }).subscribe({
            next: sensorData => {
                let {
                    value: {
                        data: {
                            updatedSensor
                        }
                    }
                } = sensorData;
                if (updatedSensor.id) {
                    if (Object.keys(state.sensorDetails).length) {
                        if (updatedSensor.uuid === state.sensorDetails.uuid) {
                            commit("setSensorDetails", updatedSensor)
                            dispatch("sortAndSetAlarms", updatedSensor.alarms.items)
                        }
                    }                        
                    else {
                        commit("updateSensor", updatedSensor)
                    }
                }
            }
        });
        commit("setSensorSubscribe", sub)
    },
    async allSensorsSubscription({
        commit,
        state,
        dispatch
    }) {
        if (state.sensorsSubscribe) return
        if (state.sensorSubscribe) {
            // console.log(state.sensorSubscribe)
            await dispatch("unsubscribeSingleSensor")
            // console.log(state.sensorSubscribe)
        }
        // console.log("allSensorsSubscription", state.sensorsSubscribe)
        // console.log("allSensorsSubscription", state.sensorSubscribe)
        // console.log(state.singleSubscribe)
        let sub = API.graphql({
            query: updateAllSensors,
            variables: {}
        }).subscribe({
            next: sensorData => {
                let {
                    value: {
                        data: {
                            onUpdateSensor
                        }
                    }
                } = sensorData;
                if (onUpdateSensor.id) {
                    if (Object.keys(state.sensorDetails).length) {
                        if (onUpdateSensor.uuid === state.sensorDetails.uuid) {
                            console.log('onUpdateSensor: 1', onUpdateSensor);
                            commit("updateSensor", onUpdateSensor)
                            commit("setSensorDetails", onUpdateSensor)
                            dispatch("sortAndSetAlarms", onUpdateSensor.alarms.items)
                        }
                    }                        
                    else {
                        console.log('onUpdateSensor: 2', onUpdateSensor);
                        commit("updateSensor", onUpdateSensor)
                    }
                }
            }
        });
        commit("setSensorsSubscribe", sub)
    },
    allCustomerSensorsSubscription({
        commit,
        state,
        dispatch
    }, sensorUserId) {
        if (state.sensorsSubscribe) return
        let sub = API.graphql({
            query: updateAllCustomerSensors,
            variables: {
                sensorUserId
            }
        }).subscribe({
            next: sensorData => {
                let {
                    value: {
                        data: {
                            updatedSensors
                        }
                    }
                } = sensorData;
                if (updatedSensors.id) {
                    if (Object.keys(state.sensorDetails).length) {
                        if (updatedSensors.uuid === state.sensorDetails.uuid) {
                            commit("setSensorDetails", updatedSensors)
                            dispatch("sortAndSetAlarms", updatedSensors.alarms.items)
                        }
                    }                        
                    else {
                        commit("updateSensor", updatedSensors)
                    }
                }
            }
        });
        commit("setSensorsSubscribe", sub)
    },
    newSensorSubscription({
        commit
    }, user) {
        if (state.newSensorSubscribe) return
        let sub = API.graphql({
            query: createSensor,
            variables: {}
        }).subscribe({
            next: sensorData => {
                let {
                    value: {
                        data: {
                            onCreateSensor
                        }
                    }
                } = sensorData;
                if (user['custom:auth'] === "super") commit("addSingleSensor", onCreateSensor)
                else if (user['custom:auth'] === "customer") {
                    if (onCreateSensor.email === user.email) commit("addSingleSensor", onCreateSensor)
                }
            }
        });
        commit("setNewSensorSubscribe", sub)
    },
    deleteSensorSubscription({
        commit,
        state,
        dispatch
    }) {
        let sub = API.graphql({
            query: subscriptions.onDeleteSensor,
            variables: {}
        }).subscribe({
            next: sensorData => {
                let {
                    value: {
                        data: {
                            onDeleteSensor
                        }
                    }
                } = sensorData;
                if (onDeleteSensor.id) {
                    dispatch("allSensors")
                }
            }
        });
        commit("setDeleteSensorSubscribe", sub)
    },
    readingSubscription({
        commit,
        state,
        dispatch
    }) {
        let sub = API.graphql({
            query: subscriptions.onCreateReading,
            variables: {}
        }).subscribe({
            next: readingData => {
                let {
                    value: {
                        data: {
                            onCreateReading
                        }
                    }
                } = readingData;
                let ind = state.sensors.findIndex(item => item.id === onCreateReading.readingSensorId)
                if (ind === -1) {
                    dispatch("allSensors")
                } else commit("addReading", onCreateReading);

            }
        });
        commit("setSubscribe", sub)
    },
    singleReadingSubscription({
        commit,
        state
    }, sensorId) {
        let sub = API.graphql({
            query: subscriptions.onCreateReading
        }).subscribe({
            next: readingData => {
                let {
                    value: {
                        data: {
                            onCreateReading
                        }
                    }
                } = readingData;
                if (sensorId === onCreateReading.readingSensorId) {
                    commit("addSingleReading", onCreateReading);
                }
            }
        });
        commit("setSingleSubscribe", sub)
    },
    async emptySingleSensor({
        commit,
        state
    }) {
        if (state.singleSubscribe) await state.singleSubscribe.unsubscribe()
        commit("emptySensor")
        commit("emptySensorDetails")
        commit("removeSingleSubscribe")
    },
    async emptyLogs({
        commit,
        state
    }) {
        if (state.logSubscribe) await state.logSubscribe.unsubscribe()
        commit("emptySensorLogs")
        commit("removeLogSubscribe")
    },
    async unsubscribeSingleSensor({
        commit,
        state
    }) {
        console.log("unsubscribeSingleSensor")
        if (state.singleSubscribe) await state.singleSubscribe.unsubscribe()
        console.log(state.singleSubscribe)
        commit("emptySensorDetails")
        commit("removeSingleSubscribe")
        console.log(state.singleSubscribe)
    },
    async updateCurrentSensorDetails({
        commit,
    }, sensor) {
        commit("setSensorDetails", sensor)
    },
    async unsubscribeSensor({
        commit,
        state
    }) {
        if (state.sensorSubscribe) await state.sensorSubscribe.unsubscribe()
        commit("removeSensorSubscribe")
        // commit("emptySensors")
    },
    async unsubscribeSensors({
        commit,
        state
    }) {
        if (state.sensorsSubscribe) await state.sensorsSubscribe.unsubscribe()
        commit("removeSensorsSubscribe")
        // commit("emptySensors")
    },
    async unsubscribeNewSensor({
        commit,
        state
    }) {
        if (state.newSensorSubscribe) await state.newSensorSubscribe.unsubscribe()
        commit("removeNewSensorSubscribe")
    },
    async emptyAllSensors({
        commit,
        state
    }) {
        if (state.sensorSubscribe) await state.sensorSubscribe.unsubscribe()
        commit("emptySensors")
        // commit("removeSubscribe")
    },
    async getSensorReports({
        commit,
        dispatch
    }, {sensorId, limit}) {

        limit = limit || 10;

        // console.log('genSensorReports: ', sensorId);

        try {
            const result = await API.graphql({
                query: getSortedSensorReports,
                variables: {
                    limit,
                    reportSensorId: sensorId,
                    sortDirection: 'DESC'
                }
            })
            // console.log('result: ', result);
            return result.data.getSortedSensorReports.items;
        } catch (err) {
            // console.log('error in gql: ', err);
            throw err;
        }

        // if (!getUser.store) commit("setSensors", await dispatch("formatUserSensors", [getUser.location]))
        // else commit("setSensors", await dispatch("formatUserSensors", getUser.store.locations.items))
        // dispatch("readingSubscription")
    },
    convertFahrenheitToCelsius({},temp) {
        return ((temp - 32) * 5/9).toFixed(0) 
    },
    async getSensorReport({
        commit,
        dispatch
    }, params) {

        // console.log('getSensorReport() params: ', params);
        const {sensorUuid, periodStartTimestamp} = params;

        const {
            data: {
                sensorByUUID: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.sensorByUUID,
            variables: {
                uuid: sensorUuid
            }
        })
        const sensor = items[0]

        if (!sensor) {
            throw new Error('Failed to load sensor for uuid: ' + sensorUuid)
        }

        // console.log('Loaded sensor: ', sensor);

        try {
            const result = await API.graphql({
                query: getSortedSensorReports,
                variables: {
                    limit: 10,
                    reportSensorId: sensor.id,
                    periodStartTimestamp: {
                        eq: periodStartTimestamp
                    },
                    sortDirection: 'DESC'
                }
            })
            // console.log('result: ', result);
            return result.data.getSortedSensorReports.items[0];
        } catch (err) {
            // console.log('error in gql: ', err);
            throw err;
        }

        // const result = await API.graphql({
        //     query: `query ListReports(
        //             $filter: ModelReportFilterInput
        //             $limit: Int
        //             $nextToken: String
        //             ) {
        //             listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
        //               items {
        //                 id
        //                 createdAt
        //                 period
        //                 periodStartTimestamp
        //                 highTemps
        //                 lowTemps
        //                 sensor {
        //                     id
        //                     name
        //                     high
        //                     low
        //                 }
        //               }
        //             }
        //           }`,
        //     variables: {
        //         limit: 1000,
        //         filter: {
        //             id: {
        //                 eq: params.id
        //             }
        //         }
        //     }
        // })

        // const result = await API.graphql({
        //     query: getReport,
        //     variables: {
        //         id
        //     }
        // })

        // console.log('get report result: ', result);
        // return result.data.listReports.items[0];

        // return {
        //     "ID": 1234,
        //     "reportDetails": [
        //         {
        //             "type": "numberOfTemperatureExcursions",
        //             "valueInt": 4
        //         }
        //     ]
        // }
        // const {
        //     data: {
        //         getUser
        //     }
        // } = await API.graphql({
        //     query: listUserSensors,
        //     variables: {
        //         id: userId
        //     }
        // })
        // if (!getUser.store) commit("setSensors", await dispatch("formatUserSensors", [getUser.location]))
        // else commit("setSensors", await dispatch("formatUserSensors", getUser.store.locations.items))
        // dispatch("readingSubscription")
    }
}

const mutations = {
    setSensor(state, sensor) {
        state.sensor = sensor
    },
    removeSingleSensor(state, sensorId) {
        let index = state.sensors.findIndex(item => item.id === sensorId)
        state.sensors.splice(index, 1)
    },
    setTags(state, tags) {
        state.tags = tags
    },
    setAlarms(state, alarms) {
        state.alarms = alarms
    },
    addLog(state, log) {
        state.logs.push(log)
    },
    addNewTag(state, tag) {
        state.tags.push(tag)
    },
    removeTag(state, sensorTag) {
        let sensorIndex = state.sensors.findIndex(item => item.id === sensorTag.sensor.id)
        let tagIndex = state.sensors[sensorIndex].tags.findIndex(item => item.id === sensorTag.tag.id)
        state.sensors[sensorIndex].tags.splice(tagIndex, 1)
    },
    addNewSensorTag(state, sensorTag) {
        let index = state.sensors.findIndex(item => item.id === sensorTag.sensor.id)
        state.sensors[index].tags.push({
            id: sensorTag.tag.id,
            name: sensorTag.tag.name,
            sensorTagId: sensorTag.id
        })
    },
    setLocations(state, locations) {
        state.locations = locations
    },
    setSensors(state, sensors) {
        // state.sensors = (state.sensors.concat(...sensors)).sort((a,b) => a.name - b.name)
        state.sensors = state.sensors.concat(...sensors)
    },
    setLogs(state, logs) {
        state.logs = logs
    },
    addReading(state, reading) {
        let index = state.sensors.findIndex(item => item.id === reading.readingSensorId)
        state.sensors[index].reading = reading
    },
    updateSensorDetailsOnline(state) {
        state.sensorDetails.online = true
    },
    updateSensorDetailsOffline(state) {
        state.sensorDetails.online = false
    },
    addSingleReading(state, reading) {
        state.sensor.push(reading)
    },
    addSingleSensor(state, sensor) {
        state.sensors.push(sensor)
    },
    setSensorSubscribe(state, sub) {
        if (state.sensorSubscribe) return
        state.sensorSubscribe = sub
    },
    setSensorsSubscribe(state, sub) {
        if (state.sensorsSubscribe) return
        state.sensorsSubscribe = sub
    },
    setNewSensorSubscribe(state, sub) {
        if (state.newSensorSubscribe) return
        state.newSensorSubscribe = sub
    },
    setDeleteSensorSubscribe(state, sub) {
        if (state.sensorDeleteSubscribe) return
        state.sensorDeleteSubscribe = sub
    },
    setDevices(state, devices) {
        state.devices = devices
    },
    setSubscribe(state, sub) {
        if (state.allSubscribe) return
        state.allSubscribe = sub
    },
    setSingleSubscribe(state, sub) {
        if (state.singleSubscribe) return
        state.singleSubscribe = sub
    },
    setLogSubscribe(state, sub) {
        if (state.logSubscribe) return
        state.logSubscribe = sub
    },
    emptySensor(state) {
        state.sensor = []
    },
    emptySensorDetails(state) {
        state.sensorDetails = {}
    },
    emptySensors(state) {
        state.sensors = []
    },
    emptySensorLogs(state) {
        state.logs = []
    },
    removeSubscribe(state) {
        state.allSubscribe = ""
    },
    removeSensorSubscribe(state) {
        state.sensorSubscribe = ""
    },
    removeSensorsSubscribe(state) {
        state.sensorsSubscribe = ""
    },
    removeNewSensorSubscribe(state) {
        state.newSensorSubscribe = ""
    },
    removeSingleSubscribe(state) {
        state.singleSubscribe = ""
    },
    removeLogSubscribe(state) {
        state.logSubscribe = ""
    },
    updateSensor(state, sensor) {
        console.log(sensor)
        let index = state.sensors.findIndex(item => item.id === sensor.id)
        if (index === -1) {
            return
        }
        state.sensors.splice(index, 1, sensor)
    },
    updateSensorMode(state, sensor) {
        let index = state.sensors.findIndex(item => item.id === sensor.id)
        state.sensors[index].development = !!sensor.development
    },
    reverseSensorMode(state, sensor) {
        let index = state.sensors.findIndex(item => item.id === sensor.id)
        state.sensors[index].development = !state.sensors[index].development
    },
    setDeviceType(state, type) {
        state.deviceType = type
    },
    setSensorDetails(state, details) {
        state.sensorDetails = details
    },
    setSensorEvents(state, events) {
        state.sensorEvents = events
    },
    setSensorProducts(state, products) {
        state.sensorProducts = products
    },
    removeStockItem(state, devEui) {
        let index = state.stock.findIndex(item => item.devEui === devEui)
        if (index === -1) {
            return
        }
        state.stock.splice(index, 1)
    },
    setSensorStock(state, stock) {
        state.stock = stock
    },
    setSensorReports(state, reports) {
        state.sensorReports = reports
    },
    setBTDevice(state, device) {
        state.btDevice = device
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
